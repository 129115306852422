/*
   THIS COMPONENT WAS COPIED FROM SWAGGER UI.
   IT HAS BEEN CUSTOMIZED FOR THE CC API PORTAL AND RENAMED (Response to ResponseReplacement)
   See SwaggerUiLayout.jsx for usage
 */

import React from "react"
import PropTypes from "prop-types"
import ImPropTypes from "react-immutable-proptypes"
import { fromJS, Iterable, Map } from "immutable"

export default class ResponseReplacement extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            responseContentType: "",
        }
    }

    static propTypes = {
        path: PropTypes.string.isRequired,
        method: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        response: PropTypes.instanceOf(Iterable),
        className: PropTypes.string,
        getComponent: PropTypes.func.isRequired,
        getConfigs: PropTypes.func.isRequired,
        specSelectors: PropTypes.object.isRequired,
        oas3Actions: PropTypes.object.isRequired,
        specPath: ImPropTypes.list.isRequired,
        fn: PropTypes.object.isRequired,
        contentType: PropTypes.string,
        activeExamplesKey: PropTypes.string,
        controlsAcceptHeader: PropTypes.bool,
        onContentTypeChange: PropTypes.func
    }

    static defaultProps = {
        response: fromJS({}),
        onContentTypeChange: () => {}
    };

    _onContentTypeChange = (value) => {
        const { onContentTypeChange, controlsAcceptHeader } = this.props
        this.setState({ responseContentType: value })
        onContentTypeChange({
            value: value,
            controlsAcceptHeader
        })
    }

    getTargetExamplesKey = () => {
        const { response, contentType, activeExamplesKey } = this.props

        const activeContentType = this.state.responseContentType || contentType
        const activeMediaType = response.getIn(["content", activeContentType], Map({}))
        const examplesForMediaType = activeMediaType.get("examples", null)

        const firstExamplesKey = examplesForMediaType.keySeq().first()
        return activeExamplesKey || firstExamplesKey
    }

    render() {
        let {
            code,
            response,
            className,
            getComponent,
            specSelectors,
        } = this.props

        let isOAS3 = specSelectors.isOAS3()

        let links = response.get("links")

        const OperationLink = getComponent("operationLink")

        return (
            <tr className={ "response " + ( className || "") } data-code={code}>
                <td className="response-col_status" style={{padding: "10px"}}>
                    { code }
                </td>
                <td className="response-col_description">
                    <div className="">
                        { response.get( "description" ) }
                    </div>
                </td>
                {isOAS3 ? <td className="response-col_links">
                    { links ?
                        links.toSeq().map((link, key) => {
                            return <OperationLink key={key} name={key} link={ link } getComponent={getComponent}/>
                        })
                        : <i>No links</i>}
                </td> : null}
            </tr>
        )
    }
}