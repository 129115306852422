/*
   THIS COMPONENT WAS COPIED FROM SWAGGER UI.
   IT HAS BEEN CUSTOMIZED FOR CC API PORTAL AND RENAMED (Operation to OperationReplacement)
   See SwaggerUiLayout.jsx for usage
 */

import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { getList } from "../utils"
import { getExtensions, sanitizeUrl, escapeDeepLinkPath } from "../utils"
import { Iterable, List } from "immutable"
import ImPropTypes from "react-immutable-proptypes"
import LiveResponses from "./live-responses"
import { Tab } from 'semantic-ui-react'
import { store } from '../../../services/state.js'
import SyntaxHighlighter from 'react-syntax-highlighter';
import frontmatter from "front-matter";

export default class OperationReplacement extends PureComponent {

    static propTypes = {
        specPath: ImPropTypes.list.isRequired,
        operation: PropTypes.instanceOf(Iterable).isRequired,
        summary: PropTypes.string,
        response: PropTypes.instanceOf(Iterable),
        request: PropTypes.instanceOf(Iterable),

        toggleShown: PropTypes.func.isRequired,
        onTryoutClick: PropTypes.func.isRequired,
        onCancelClick: PropTypes.func.isRequired,
        onExecute: PropTypes.func.isRequired,

        getComponent: PropTypes.func.isRequired,
        getConfigs: PropTypes.func.isRequired,
        authActions: PropTypes.object,
        authSelectors: PropTypes.object,
        specActions: PropTypes.object.isRequired,
        specSelectors: PropTypes.object.isRequired,
        oas3Actions: PropTypes.object.isRequired,
        oas3Selectors: PropTypes.object.isRequired,
        layoutActions: PropTypes.object.isRequired,
        layoutSelectors: PropTypes.object.isRequired,
        fn: PropTypes.object.isRequired
    }

    static defaultProps = {
        operation: null,
        response: null,
        request: null,
        specPath: List(),
        summary: ""
    }

    getCodeSamplePath(title, path, method){
        return '/api-files/' + title.replace(/\s+/g, '-').toLowerCase() + '/code' + path.toLowerCase() + '/' + method.toLowerCase() + '/'
    }

    loadCodeSample(filePath, fileName, url, method, callback) {
        fetch(filePath + fileName)
            .then(response => response.text())
            .then(codeSample => {
                // code sample not found, use default code samples
                if (codeSample.includes('<!doctype html>')) {
                    fetch('/api-files/_defaults/code/' + method.toLowerCase() + '/' + fileName)
                        .then(response => response.text())
                        .then(defaultCodeSample => {
                            this.callbackWithCodeSample(defaultCodeSample, url, callback)
                        })
                }
                this.callbackWithCodeSample(codeSample, url, callback)
        })
    }

    callbackWithCodeSample(codeSample, url, callback) {
        // eslint-disable-next-line
        let parsedMarkdown = frontmatter(codeSample.replace('${url}', url))
        callback(parsedMarkdown.body)
    }

    loadCodeSamples() {

        let {specSelectors} = this.props
        let operationProps = this.props.operation
        let {path, method} = operationProps.toJS()

        const url = "https://" + specSelectors.host() + specSelectors.basePath() + path

        const codeSamplePath = this.getCodeSamplePath(store.api.swagger.info.title, path, method)

        this.loadCodeSample(codeSamplePath, 'javascript.md', url, method, (codeSample) => {
            this.setState({
                javascript: codeSample
            })
        })
        this.loadCodeSample(codeSamplePath, 'python.md', url, method, (codeSample) => {
            this.setState({
                python: codeSample
            })
        })
        this.loadCodeSample(codeSamplePath, 'php.md', url, method, (codeSample) => {
            this.setState({
                php: codeSample
            })
        })
        this.loadCodeSample(codeSamplePath, 'java.md', url, method, (codeSample) => {
            this.setState({
                java: codeSample
            })
        })
    }

    componentDidMount() {
        this.loadCodeSamples()
    }

    render() {
        let {
            specPath,
            response,
            request,
            toggleShown,
            onTryoutClick,
            onCancelClick,
            onExecute,
            fn,
            getComponent,
            getConfigs,
            specActions,
            specSelectors,
            authActions,
            authSelectors,
            oas3Actions,
            oas3Selectors
        } = this.props
        let operationProps = this.props.operation

        let {
            deprecated,
            isShown,
            path,
            method,
            op,
            tag,
            operationId,
            allowTryItOut,
            displayRequestDuration,
            tryItOutEnabled,
            executeInProgress
        } = operationProps.toJS()

        // have "try it out" on by default
        tryItOutEnabled = true

        let {
            description,
            externalDocs,
            schemes
        } = op

        let operation = operationProps.getIn(["op"])
        let responses = operation.get("responses")
        let parameters = getList(operation, ["parameters"])
        let operationScheme = specSelectors.operationScheme(path, method)
        let isShownKey = ["operations", tag, operationId]
        let extensions = getExtensions(operation)

        const Responses = getComponent("responses")
        const Parameters = getComponent( "parameters" )
        const Execute = getComponent( "execute" )
        const Clear = getComponent( "clear" )
        const Collapse = getComponent( "Collapse" )
        const Markdown = getComponent( "Markdown" )
        const Schemes = getComponent( "schemes" )
        const OperationServers = getComponent( "OperationServers" )
        const OperationExt = getComponent( "OperationExt" )
        const OperationSummary = getComponent( "OperationSummary" )
        const Link = getComponent( "Link" )

        const { showExtensions } = getConfigs()

        // Merge in Live Response
        if(responses && response && response.size > 0) {
            let notDocumented = !responses.get(String(response.get("status"))) && !responses.get("default")
            response = response.set("notDocumented", notDocumented)
        }

        let onChangeKey = [ path, method ] // Used to add values to _this_ operation ( indexed by path and method )


        const panes = [
            { menuItem: 'Server Response', render: () =>

                <Tab.Pane style={{minHeight: "200px"}}>
                    {!response && !executeInProgress ? <div style={{padding: "30px"}}><h4>No request made</h4></div> : null}
                    { executeInProgress || !responses ?  null :
                        <LiveResponses
                            responses={ responses }
                            request={ request }
                            tryItOutResponse={ response }
                            getComponent={ getComponent }
                            getConfigs={ getConfigs }
                            specSelectors={ specSelectors }
                            oas3Actions={oas3Actions}
                            oas3Selectors={oas3Selectors}
                            specActions={ specActions }
                            produces={specSelectors.producesOptionsFor([path, method]) }
                            producesValue={ specSelectors.currentProducesFor([path, method]) }
                            specPath={specPath.push("responses")}
                            path={ path }
                            method={ method }
                            displayRequestDuration={ displayRequestDuration }
                            fn={fn} />
                    }
                    {executeInProgress ? <div style={{paddingTop: "40px"}}><div className="loading-container"><div className="loading"></div></div></div> : null}
                </Tab.Pane>

                },
            { menuItem: 'JavaScript', render: () =>
                    <Tab.Pane  style={{minHeight: "200px", padding: "20px"}}>
                        <h4>JavaScript (NodeJS) Code Sample</h4>
                        <SyntaxHighlighter language="javascript" useInlineStyles={false}>
                            {this.state.javascript}
                        </SyntaxHighlighter>
                    </Tab.Pane> },
            { menuItem: 'Python', render: () =>
                    <Tab.Pane  style={{minHeight: "200px", padding: "20px"}}>
                        <h4>Python Code Sample</h4>
                        <SyntaxHighlighter language="python" useInlineStyles={false}>
                            {this.state.python}
                        </SyntaxHighlighter>
                    </Tab.Pane> },
            { menuItem: 'PHP', render: () =>
                    <Tab.Pane  style={{minHeight: "200px", padding: "20px"}}>
                        <h4>PHP Code Sample</h4>
                        <SyntaxHighlighter language="java" useInlineStyles={false}>
                            {this.state.php}
                        </SyntaxHighlighter>
                    </Tab.Pane> },
            { menuItem: 'Java', render: () =>
                    <Tab.Pane  style={{minHeight: "200px", padding: "20px"}}>
                        <h4>Java Code Sample</h4>
                        <SyntaxHighlighter language="java" useInlineStyles={false}>
                            {this.state.java}
                        </SyntaxHighlighter>
                    </Tab.Pane> },
        ]


        return (
            <div className={deprecated ? "opblock opblock-deprecated" : isShown ? `opblock opblock-${method} is-open` : `opblock opblock-${method}`} id={escapeDeepLinkPath(isShownKey.join("-"))} >
                <OperationSummary operationProps={operationProps} toggleShown={toggleShown} getComponent={getComponent} authActions={authActions} authSelectors={authSelectors} specPath={specPath} />
                <Collapse isOpened={isShown}>
                    <div className="opblock-body">
                        { (operation && operation.size) || operation === null ? null :
                            // eslint-disable-next-line
                             <img height={"32px"} width={"32px"} src={require("../../img/rolling-load.svg")} className="opblock-loading-animation" />
                        }
                        { deprecated && <h4 className="opblock-title_normal"> Warning: Deprecated</h4>}
                        { description &&
                        <div className="opblock-description-wrapper">
                            <div className="opblock-description">
                                <Markdown source={ description } />
                            </div>
                        </div>
                        }
                        {
                            externalDocs && externalDocs.url ?
                                <div className="opblock-external-docs-wrapper">
                                    <h4 className="opblock-title_normal">Find more details</h4>
                                    <div className="opblock-external-docs">
                    <span className="opblock-external-docs__description">
                      <Markdown source={ externalDocs.description } />
                    </span>
                                        <Link target="_blank" className="opblock-external-docs__link" href={sanitizeUrl(externalDocs.url)}>{externalDocs.url}</Link>
                                    </div>
                                </div> : null
                        }
                <table>
                <tr><td style={{width: "50%", verticalAlign: "top"}}>
                        { !operation || !operation.size ? null :
                            <Parameters
                                parameters={parameters}
                                specPath={specPath.push("parameters")}
                                operation={operation}
                                onChangeKey={onChangeKey}
                                onTryoutClick = { onTryoutClick }
                                onCancelClick = { onCancelClick }
                                tryItOutEnabled = { tryItOutEnabled }
                                allowTryItOut={allowTryItOut}

                                fn={fn}
                                getComponent={ getComponent }
                                specActions={ specActions }
                                specSelectors={ specSelectors }
                                pathMethod={ [path, method] }
                                getConfigs={ getConfigs }
                                oas3Actions={ oas3Actions }
                                oas3Selectors={ oas3Selectors }
                            />
                        }

                        { !tryItOutEnabled ? null :
                            <OperationServers
                                getComponent={getComponent}
                                path={path}
                                method={method}
                                operationServers={operation.get("servers")}
                                pathServers={specSelectors.paths().getIn([path, "servers"])}
                                getSelectedServer={oas3Selectors.selectedServer}
                                setSelectedServer={oas3Actions.setSelectedServer}
                                setServerVariableValue={oas3Actions.setServerVariableValue}
                                getServerVariable={oas3Selectors.serverVariableValue}
                                getEffectiveServerValue={oas3Selectors.serverEffectiveValue}
                            />
                        }

                        {!tryItOutEnabled || !allowTryItOut ? null : schemes && schemes.size ? <div className="opblock-schemes">
                            <Schemes schemes={ schemes }
                                     path={ path }
                                     method={ method }
                                     specActions={ specActions }
                                     currentScheme={ operationScheme } />
                        </div> : null
                        }


                        { !responses ? null :
                            <Responses
                                responses={ responses }
                                request={ request }
                                tryItOutResponse={ response }
                                getComponent={ getComponent }
                                getConfigs={ getConfigs }
                                specSelectors={ specSelectors }
                                oas3Actions={oas3Actions}
                                oas3Selectors={oas3Selectors}
                                specActions={ specActions }
                                produces={specSelectors.producesOptionsFor([path, method]) }
                                producesValue={ specSelectors.currentProducesFor([path, method]) }
                                specPath={specPath.push("responses")}
                                path={ path }
                                method={ method }
                                displayRequestDuration={ displayRequestDuration }
                                fn={fn} />
                        }

                        { !showExtensions || !extensions.size ? null :
                            <OperationExt extensions={ extensions } getComponent={ getComponent } />
                        }


                        <div className={(!tryItOutEnabled || !response || !allowTryItOut) ? "execute-wrapper" : "btn-group"}>
                            { !tryItOutEnabled || !allowTryItOut ? null :

                                <Execute
                                    operation={ operation }
                                    specActions={ specActions }
                                    specSelectors={ specSelectors }
                                    path={ path }
                                    method={ method }
                                    onExecute={ onExecute } />
                            }

                            { (!tryItOutEnabled || !response || !allowTryItOut) ? null :
                                <Clear
                                    specActions={ specActions }
                                    path={ path }
                                    method={ method }/>
                            }
                        </div>



                </td>
                <td style={{width: "50%", verticalAlign: "top"}}>
                    <Tab panes={panes} style={{padding: "10px"}}/>
                </td>
                </tr>
                </table>

                    </div>
                </Collapse>
            </div>
        )
    }
}