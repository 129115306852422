/*
   THIS COMPONENT WAS COPIED FROM SWAGGER UI.
   IT HAS BEEN CUSTOMIZED FOR THE CC API PORTAL AND RENAMED (LiveResponse to LiveResponseReplacement)
   See SwaggerUiLayout.jsx for usage
 */

import React from "react"
import PropTypes from "prop-types"
import ImPropTypes from "react-immutable-proptypes"
import { Iterable } from "immutable"

const Headers = ( { headers } )=>{
    return (
        <div>
            <h5>Response headers</h5>
            <pre>{headers}</pre>
        </div>)
}
Headers.propTypes = {
    headers: PropTypes.array.isRequired
}

const Duration = ( { duration } ) => {
    return (
        <div>
            <h5>Request duration</h5>
            <pre>{duration} ms</pre>
        </div>
    )
}
Duration.propTypes = {
    duration: PropTypes.number.isRequired
}


export default class LiveResponseReplacement extends React.Component {
    static propTypes = {
        response: PropTypes.instanceOf(Iterable).isRequired,
        path: PropTypes.string.isRequired,
        method: PropTypes.string.isRequired,
        displayRequestDuration: PropTypes.bool.isRequired,
        specSelectors: PropTypes.object.isRequired,
        getComponent: PropTypes.func.isRequired,
        getConfigs: PropTypes.func.isRequired
    }

    shouldComponentUpdate(nextProps) {
        // BUG: props.response is always coming back as a new Immutable instance
        // same issue as responses.jsx (tryItOutResponse)
        return this.props.response !== nextProps.response
            || this.props.path !== nextProps.path
            || this.props.method !== nextProps.method
            || this.props.displayRequestDuration !== nextProps.displayRequestDuration
    }

    render() {
        const { response, getComponent, getConfigs, specSelectors, path, method } = this.props
        const { showMutatedRequest } = getConfigs()

        const curlRequest = showMutatedRequest ? specSelectors.mutatedRequestFor(path, method) : specSelectors.requestFor(path, method)
        const status = response.get("status")
        const url = curlRequest.get("url")
        const headers = response.get("headers").toJS()
        const isError = response.get("error")
        const body = response.get("text")
        const headersKeys = Object.keys(headers)
        const contentType = headers["content-type"] || headers["Content-Type"]

        const Curl = getComponent("curl")
        const ResponseBody = getComponent("responseBody")
        const returnObject = headersKeys.map(key => {
            return <span className="headerline" key={key}> {key}: {headers[key]} </span>
        })

        return (
            <div>

                { curlRequest && <Curl request={ curlRequest }/> }
                { url &&
                    <div>
                        <h4>Request URL</h4>
                        <div className="request-url">
                            <pre>{url}</pre>
                        </div>
                    </div>
                }

                <div>
                    <h4>Response HTTP Status Code</h4>
                    <pre>{ status }</pre>
                </div>

                <div>
                    {isError ?
                        <div>
                        <h4>Error Response</h4>
                        <pre>{`${response.get("name")}: ${response.get("message")}`}</pre>
                        </div>
                        : null}

                    {body ?
                        <ResponseBody content={body}
                                      contentType={contentType}
                                      url={url}
                                      headers={headers}
                                      getComponent={getComponent}/>
                    : null}
                </div>

                <div>
                    <Headers headers={ returnObject }/>
                </div>
            </div>
        )
    }

    static propTypes = {
        getComponent: PropTypes.func.isRequired,
        response: ImPropTypes.map
    }
}