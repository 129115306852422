import React from "react"
import PropTypes from "prop-types"
import ImPropTypes from "react-immutable-proptypes"

export default class ModelExampleReplacement extends React.Component {
    static propTypes = {
        getComponent: PropTypes.func.isRequired,
        specSelectors: PropTypes.object.isRequired,
        schema: PropTypes.object.isRequired,
        example: PropTypes.any.isRequired,
        isExecute: PropTypes.bool,
        getConfigs: PropTypes.func.isRequired,
        specPath: ImPropTypes.list.isRequired,
    }

    constructor(props, context) {
        super(props, context)
        let { getConfigs, isExecute } = this.props
        let { defaultModelRendering } = getConfigs()

        let activeTab = defaultModelRendering

        if (defaultModelRendering !== "example" && defaultModelRendering !== "model") {
            activeTab = "example"
        }

        if(isExecute) {
            activeTab = "example"
        }

        this.state = {
            activeTab: activeTab
        }
    }

    activeTab =( e ) => {
        let { target : { dataset : { name } } } = e

        this.setState({
            activeTab: name
        })
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.isExecute &&
            !this.props.isExecute &&
            this.props.example
        ) {
            this.setState({ activeTab: "example" })
        }
    }

    render() {
        let { getComponent, specSelectors, schema, example, getConfigs, specPath } = this.props
        let { defaultModelExpandDepth } = getConfigs()
        const ModelWrapper = getComponent("ModelWrapper")
        const HighlightCode = getComponent("highlightCode")

        return <div className="model-example">
            <h4 className="opblock-title">Request Body</h4>
            <div>
                {
                    this.state.activeTab === "example" ? (
                        example ? example : (
                            <HighlightCode value="(no example available)" />
                        )
                    ) : null
                }
                {
                    this.state.activeTab === "model" && <ModelWrapper schema={ schema }
                                                                      getComponent={ getComponent }
                                                                      getConfigs={ getConfigs }
                                                                      specSelectors={ specSelectors }
                                                                      expandDepth={ defaultModelExpandDepth }
                                                                      specPath={specPath} />


                }
            </div>
        </div>
    }

}