// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { Link } from 'react-router-dom'
import { Menu, Image } from 'semantic-ui-react'
// mobx
import { observer } from 'mobx-react'
import {withRouter} from 'react-router-dom';
import { isAdmin, isAuthenticated, logout, getLoginRedirectUrl } from 'services/self'

import {HAS_DASHBOARD} from "../env";
import {cognitoClientId, cognitoDomain} from "../services/api";
import Register from "./Register";

export const NavBar = observer(
  class NavBar extends React.Component {

      getCognitoUrl = (type) => {
          let redirectUri = getLoginRedirectUrl()
          return `${cognitoDomain}/${type}?response_type=token&client_id=${cognitoClientId}&redirect_uri=${redirectUri}`
      }

      insertAuthMenu() {
          return isAuthenticated() ?
              (
                  <Menu.Menu position="right">
                      {isAdmin() && <Menu.Item as={Link} to="/admin">Admin Panel</Menu.Item>}
                      {HAS_DASHBOARD && <Menu.Item key="dashboard" as={Link} to="/dashboard">My Dashboard</Menu.Item>}
                      <Menu.Item key="signout" as="a" onClick={logout}>Sign Out</Menu.Item>
                  </Menu.Menu>
              ) : (
                  <Menu.Menu position="right">
                      <Menu.Item key="register" as="a"
                                 href={this.getCognitoUrl('login')}>
                          Sign In
                      </Menu.Item>
                      <Register />
                  </Menu.Menu>
              )
      }

    render() {
      const url = this.props.location.pathname
      if(url === '/user-login'){
        return null;
      }
      return <Menu inverted borderless attached style={{ flex: "0 0 auto" }} >
        <a href="https://www.clearcapital.com/" target={"_blank"} style={{padding: "12px"}}>
          <Image src="/custom-content/nav-logo.png" style={{ paddingRight: "30px" }} />
          {/*{fragments.Home.title}*/}
        </a>

        {/*<Menu.Item as={Link} to="/getting-started">{fragments.GettingStarted.title}</Menu.Item>*/}
        {/*<Menu.Item as={Link} to="/apis/introduction">{fragments.APIs.title}</Menu.Item>*/}

        {this.insertAuthMenu()}
      </Menu >
    }
  }
)

export default withRouter(NavBar)
