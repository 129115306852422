/*
   THIS COMPONENT WAS COPIED FROM SWAGGER UI.
   IT HAS BEEN CUSTOMIZED FOR THE CC API PORTAL AND RENAMED (ParameterRow to ParameterRowReplacement)
   See SwaggerUiLayout.jsx for usage
 */

import React, { Component } from "react"
import { Map, List } from "immutable"
import PropTypes from "prop-types"
import ImPropTypes from "react-immutable-proptypes"
import win from "../window"
import { getSampleSchema, getExtensions, getCommonExtensions, numberToString, stringify } from "../utils"
import getParameterSchema from "../../helpers/get-parameter-schema.js"

export default class ParameterRowReplacement extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        param: PropTypes.object.isRequired,
        rawParam: PropTypes.object.isRequired,
        getComponent: PropTypes.func.isRequired,
        fn: PropTypes.object.isRequired,
        isExecute: PropTypes.bool,
        onChangeConsumes: PropTypes.func.isRequired,
        specSelectors: PropTypes.object.isRequired,
        specActions: PropTypes.object.isRequired,
        pathMethod: PropTypes.array.isRequired,
        getConfigs: PropTypes.func.isRequired,
        specPath: ImPropTypes.list.isRequired,
        oas3Actions: PropTypes.object.isRequired,
        oas3Selectors: PropTypes.object.isRequired,
    }

    constructor(props, context) {
        super(props, context)

        this.setDefaultValue()
    }

    componentWillReceiveProps(props) {
        let { specSelectors, pathMethod, rawParam } = props
        let isOAS3 = specSelectors.isOAS3()

        let parameterWithMeta = specSelectors.parameterWithMetaByIdentity(pathMethod, rawParam) || new Map()
        // fallback, if the meta lookup fails
        parameterWithMeta = parameterWithMeta.isEmpty() ? rawParam : parameterWithMeta

        let enumValue

        if(isOAS3) {
            let { schema } = getParameterSchema(parameterWithMeta, { isOAS3 })
            enumValue = schema.get("enum")
        } else {
            enumValue = parameterWithMeta ? parameterWithMeta.get("enum") : undefined
        }
        let paramValue = parameterWithMeta ? parameterWithMeta.get("value") : undefined

        let value

        if ( paramValue !== undefined ) {
            value = paramValue
        } else if ( rawParam.get("required") && enumValue && enumValue.size ) {
            value = enumValue.first()
        }

        if ( value !== undefined && value !== paramValue ) {
            this.onChangeWrapper(numberToString(value))
        }

        this.setDefaultValue()
    }

    onChangeWrapper = (value, isXml = false) => {
        let { onChange, rawParam } = this.props
        let valueForUpstream

        // Coerce empty strings and empty Immutable objects to null
        if(value === "" || (value && value.size === 0)) {
            valueForUpstream = null
        } else {
            valueForUpstream = value
        }

        return onChange(rawParam, valueForUpstream, isXml)
    }

    _onExampleSelect = (key, /* { isSyntheticChange } = {} */) => {
        this.props.oas3Actions.setActiveExamplesMember({
            name: key,
            pathMethod: this.props.pathMethod,
            contextType: "parameters",
            contextName: this.getParamKey()
        })
    }

    onChangeIncludeEmpty = (newValue) => {
        let { specActions, param, pathMethod } = this.props
        const paramName = param.get("name")
        const paramIn = param.get("in")
        return specActions.updateEmptyParamInclusion(pathMethod, paramName, paramIn, newValue)
    }

    setDefaultValue = () => {
        let { specSelectors, pathMethod, rawParam, oas3Selectors } = this.props

        const paramWithMeta = specSelectors.parameterWithMetaByIdentity(pathMethod, rawParam) || Map()

        const { schema } = getParameterSchema(paramWithMeta, { isOAS3: specSelectors.isOAS3() })

        const parameterMediaType = paramWithMeta
            .get("content", Map())
            .keySeq()
            .first()

        const generatedSampleValue = getSampleSchema(schema.toJS(), parameterMediaType, {
            includeWriteOnly: true
        })

        if (!paramWithMeta || paramWithMeta.get("value") !== undefined) {
            return
        }

        if( paramWithMeta.get("in") !== "body" ) {
            let initialValue

            //// Find an initial value

            if (specSelectors.isSwagger2()) {
                initialValue = paramWithMeta.get("x-example")
                    || paramWithMeta.getIn(["schema", "example"])
                    || schema.getIn(["default"])
            } else if (specSelectors.isOAS3()) {
                const currentExampleKey = oas3Selectors.activeExamplesMember(...pathMethod, "parameters", this.getParamKey())
                initialValue = paramWithMeta.getIn(["examples", currentExampleKey, "value"])
                    || paramWithMeta.getIn(["content", parameterMediaType, "example"])
                    || paramWithMeta.get("example")
                    || schema.get("example")
                    || schema.get("default")
                    || paramWithMeta.get("default") // ensures support for `parameterMacro`
            }

            //// Process the initial value

            if(initialValue !== undefined && !List.isList(initialValue)) {
                // Stringify if it isn't a List
                initialValue = stringify(initialValue)
            }

            //// Dispatch the initial value

            if(initialValue !== undefined) {
                this.onChangeWrapper(initialValue)
            } else if(
                schema.get("type") === "object"
                && generatedSampleValue
                && !paramWithMeta.get("examples")
            ) {
                // Object parameters get special treatment.. if the user doesn't set any
                // default or example values, we'll provide initial values generated from
                // the schema.
                // However, if `examples` exist for the parameter, we won't do anything,
                // so that the appropriate `examples` logic can take over.
                this.onChangeWrapper(
                    List.isList(generatedSampleValue) ? (
                        generatedSampleValue
                    ) : (
                        stringify(generatedSampleValue)
                    )
                )
            }
        }
    }

    getParamKey() {
        const { param } = this.props

        if(!param) return null

        return `${param.get("name")}-${param.get("in")}`
    }

    render() {
        let {param, rawParam, getComponent, getConfigs, isExecute, fn, onChangeConsumes, specSelectors, pathMethod, specPath, oas3Selectors} = this.props

        let isOAS3 = specSelectors.isOAS3()

        const { showExtensions, showCommonExtensions } = getConfigs()

        if(!param) {
            param = rawParam
        }

        if(!rawParam) return null

        // const onChangeWrapper = (value) => onChange(param, value)
        const JsonSchemaForm = getComponent("JsonSchemaForm")
        const ParamBody = getComponent("ParamBody")
        let inType = param.get("in")
        let bodyParam = inType !== "body" ? null
            : <ParamBody getComponent={getComponent}
                         fn={fn}
                         param={param}
                         consumes={ specSelectors.consumesOptionsFor(pathMethod) }
                         consumesValue={ specSelectors.contentTypeValues(pathMethod).get("requestContentType") }
                         onChange={this.onChangeWrapper}
                         onChangeConsumes={onChangeConsumes}
                         isExecute={ isExecute }
                         specSelectors={ specSelectors }
                         pathMethod={ pathMethod }
            />

        const ModelExample = getComponent("modelExample")
        const Markdown = getComponent("Markdown")
        const ParameterExt = getComponent("ParameterExt")
        const ParameterIncludeEmpty = getComponent("ParameterIncludeEmpty")
        const ExamplesSelectValueRetainer = getComponent("ExamplesSelectValueRetainer")
        const Example = getComponent("Example")

        let { schema } = getParameterSchema(param, { isOAS3 })
        let paramWithMeta = specSelectors.parameterWithMetaByIdentity(pathMethod, rawParam) || Map()

        let isFormData = inType === "formData"
        let isFormDataSupported = "FormData" in win
        let required = param.get("required")

        let value = paramWithMeta ? paramWithMeta.get("value") : ""
        let commonExt = showCommonExtensions ? getCommonExtensions(param) : null
        let extensions = showExtensions ? getExtensions(param) : null

        let paramItems // undefined
        let paramEnum // undefined
        let paramDefaultValue // undefined
        let paramExample // undefined
        let isDisplayParamEnum = false

        if ( param !== undefined ) {
            paramItems = schema.get("items")
        }

        if (paramItems !== undefined) {
            paramEnum = paramItems.get("enum")
            paramDefaultValue = paramItems.get("default")
        } else {
            paramEnum = schema.get("enum")
        }

        if ( paramEnum !== undefined && paramEnum.size > 0) {
            isDisplayParamEnum = true
        }

        // Default and Example Value for readonly doc
        if ( param !== undefined ) {
            paramDefaultValue = schema.get("default")
            if (paramDefaultValue === undefined) {
                paramDefaultValue = param.get("default")
            }
            paramExample = param.get("example")
            if (paramExample === undefined) {
                paramExample = param.get("x-example")
            }
        }

        return (
            <tr data-param-name={param.get("name")} data-param-in={param.get("in")}>
                {bodyParam ? null :
                    <td className="parameters-col_name"
                        style={{textAlign: "right", paddingRight: "10px", paddingTop: "15px", paddingLeft: "10px"}}>
                        <div className={required ? "parameter__name " : "parameter__name"}
                             style={{fontWeight: "bold", fontSize: "14px"}}>
                            {param.get("name")}
                            {!required ? null : <span style={{color: "red"}}>&nbsp;*</span>}
                        </div>
                        {/*<div className="parameter__type">*/}
                        {/*    { type }*/}
                        {/*    { itemType && `[${itemType}]` }*/}
                        {/*    { format && <span className="prop-format">(${format})</span>}*/}
                        {/*</div>*/}
                        <div className="parameter__deprecated">
                            {isOAS3 && param.get("deprecated") ? "deprecated" : null}
                        </div>
                        {/*<div className="parameter__in">({ param.get("in") })</div>*/}
                        {!showCommonExtensions || !commonExt.size ? null : commonExt.map((v, key) => <ParameterExt
                            key={`${key}-${v}`} xKey={key} xVal={v}/>)}
                        {!showExtensions || !extensions.size ? null : extensions.map((v, key) => <ParameterExt
                            key={`${key}-${v}`} xKey={key} xVal={v}/>)}
                    </td>
                }
                <td className="parameters-col_description" style={{paddingBottom: "10px"}} colSpan={2}>


                    { (bodyParam || !isExecute) && isDisplayParamEnum ?
                        <Markdown className="parameter__enum" source={
                            "<i>Available values</i> : " + paramEnum.map(function(item) {
                                return item
                            }).toArray().join(", ")}/>
                        : null
                    }

                    { (bodyParam || !isExecute) && paramDefaultValue !== undefined ?
                        <Markdown className="parameter__default" source={"<i>Default value</i> : " + paramDefaultValue}/>
                        : null
                    }

                    {(isFormData && !isFormDataSupported) && <div>Error: your browser does not support FormData</div>}

                    {
                        isOAS3 && param.get("examples") ? (
                            <section className="parameter-controls">
                                <ExamplesSelectValueRetainer
                                    examples={param.get("examples")}
                                    onSelect={this._onExampleSelect}
                                    updateValue={this.onChangeWrapper}
                                    getComponent={getComponent}
                                    defaultToFirstExample={true}
                                    currentKey={oas3Selectors.activeExamplesMember(...pathMethod, "parameters", this.getParamKey())}
                                    currentUserInputValue={value}
                                />
                            </section>
                        ) : null
                    }

                    { bodyParam ? null
                        : <JsonSchemaForm fn={fn}
                                          getComponent={getComponent}
                                          value={ value }
                                          required={ required }
                                          disabled={!isExecute}
                                          // description={param.get("description") ? `${param.get("name")} - ${param.get("description")}` : `${param.get("name")}`}
                                          onChange={ this.onChangeWrapper }
                                          errors={ paramWithMeta.get("errors") }
                                          schema={ schema }/>
                    }


                    {
                        bodyParam && schema ?
                            <div style={{padding: "10px 20px 10px 20px"}}>
                                <ModelExample getComponent={ getComponent }
                                                                specPath={specPath.push("schema")}
                                                                getConfigs={ getConfigs }
                                                                isExecute={ isExecute }
                                                                specSelectors={ specSelectors }
                                                                schema={ schema }
                                                                example={ bodyParam }/>
                            </div>
                            : null
                    }

                    {
                        !bodyParam && isExecute ?
                            <ParameterIncludeEmpty
                                onChange={this.onChangeIncludeEmpty}
                                isIncluded={specSelectors.parameterInclusionSettingFor(pathMethod, param.get("name"), param.get("in"))}
                                isDisabled={value && value.size !== 0}
                                param={param} />
                            : null
                    }

                    {
                        isOAS3 && param.get("examples") ? (
                            <Example
                                example={param.getIn([
                                    "examples",
                                    oas3Selectors.activeExamplesMember(...pathMethod, "parameters", this.getParamKey())
                                ])}
                                getComponent={getComponent}
                            />
                        ) : null
                    }

                    { param.get("description") ? <p style={{marginTop: "5px"}}>{param.get("description")}</p> : null }
                </td>
            </tr>
        )

    }

}
