/*
   THIS COMPONENT WAS COPIED FROM SWAGGER UI (Originally named Responses).
   Responses was split apart to display Response Messages and LiveResponses in separate sections.
   This will display the live responses
 */

import React from "react"
import { fromJS, Iterable } from "immutable"
import PropTypes from "prop-types"
import ImPropTypes from "react-immutable-proptypes"

export default class LiveResponses extends React.Component {
    static propTypes = {
        tryItOutResponse: PropTypes.instanceOf(Iterable),
        responses: PropTypes.instanceOf(Iterable).isRequired,
        produces: PropTypes.instanceOf(Iterable),
        producesValue: PropTypes.any,
        displayRequestDuration: PropTypes.bool.isRequired,
        path: PropTypes.string.isRequired,
        method: PropTypes.string.isRequired,
        getComponent: PropTypes.func.isRequired,
        getConfigs: PropTypes.func.isRequired,
        specSelectors: PropTypes.object.isRequired,
        specActions: PropTypes.object.isRequired,
        oas3Actions: PropTypes.object.isRequired,
        specPath: ImPropTypes.list.isRequired,
        fn: PropTypes.object.isRequired
    }

    static defaultProps = {
        tryItOutResponse: null,
        produces: fromJS(["application/json"]),
        displayRequestDuration: false
    }

    shouldComponentUpdate(nextProps) {
        // BUG: props.tryItOutResponse is always coming back as a new Immutable instance
        let render = this.props.tryItOutResponse !== nextProps.tryItOutResponse
            || this.props.responses !== nextProps.responses
            || this.props.produces !== nextProps.produces
            || this.props.producesValue !== nextProps.producesValue
            || this.props.displayRequestDuration !== nextProps.displayRequestDuration
            || this.props.path !== nextProps.path
            || this.props.method !== nextProps.method
        return render
    }

    onChangeProducesWrapper = ( val ) => this.props.specActions.changeProducesValue([this.props.path, this.props.method], val)

    onResponseContentTypeChange = ({ controlsAcceptHeader, value }) => {
        const { oas3Actions, path, method } = this.props
        if(controlsAcceptHeader) {
            oas3Actions.setResponseContentType({
                value,
                path,
                method
            })
        }
    }

    render() {
        let {
            tryItOutResponse,
            getComponent,
            getConfigs,
            specSelectors,
            displayRequestDuration,
        } = this.props

        const LiveResponse = getComponent( "liveResponse" )

        return (
            <div>
                {!tryItOutResponse ? null :
                    <div className="responses-wrapper">
                        <div className="responses-inner">
                            <LiveResponse response={ tryItOutResponse }
                                      getComponent={ getComponent }
                                      getConfigs={ getConfigs }
                                      specSelectors={ specSelectors }
                                      path={ this.props.path }
                                      method={ this.props.method }
                                      displayRequestDuration={ displayRequestDuration } />
                        </div>
                    </div>
                }
            </div>
        )
    }
}