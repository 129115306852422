// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// semantic-ui
import { Button, Header, Container } from 'semantic-ui-react'

// services
import { subscribe, unsubscribe } from 'services/api-catalog'
import { isAuthenticated } from 'services/self'

import { GetSdkButton } from 'components/GetSdk'

// state
import { observer, Observer } from 'mobx-react'
import { store } from 'services/state.js'
import {HAS_SUBSCRIPTIONS} from "../env";

import ApiMarkdown from './ApiMarkdown'


// Create the plugin that provides our layout component
export const StandardSwaggerLayoutPlugin = () => {
  return ({
    components: {
      StandardLayout: StandardLayout,
      InfoContainer: ExtendedInfoContainer,
      SchemesContainer: SchemesReplacement,
  }})
}

function getOpenApiFile(title){
  return title.replace(/\s+/g, '-').toLowerCase()
}

class ExtendedInfoContainer extends React.Component {
  render() {
    const { specSelectors } = this.props

    const basePath = specSelectors.basePath()
    const host = specSelectors.host()
    const externalDocs = specSelectors.externalDocs()
    const docsDescription = externalDocs.get('description')
    const docsUrl = externalDocs.get('url')

    const openApiFile = getOpenApiFile(store.api.swagger.info.title)

    return (
      <Observer>
      {() =>
        <Container fluid textAlign='left' className="fixfloat" style={{padding: "40px 0px 20px 0px"}}>
          <div>
            <div style={{display: "flex"}}>
              <div>
                <section id='api-title'></section>
                <Header as='h1'>{store.api.swagger.info.title}</Header>
                <div style={{display: "flex"}}>
                  <div style={{marginRight: "20px"}}>
                    <p style={{fontWeight: "bold"}}>Endpoint</p>
                    <p style={{fontWeight: "bold"}}>OpenAPI Specification</p>
                  </div>
                  <div>
                    <p>https://{host}{basePath}</p>
                    <p>
                      <a href={'/api-files/' + openApiFile + '/' + openApiFile + '.yaml'}download>{openApiFile + '.yaml'}</a>
                    </p>
                  </div>
                </div>
                {externalDocs ? (
                <div style={{display: "flex"}}>
                  <div style={{marginTop: "20px"}}>
                    <a href={docsUrl}>{docsDescription}</a>
                  </div>
                </div>
                ) : null}
                {HAS_SUBSCRIPTIONS && (
                <SubscriptionButtons/>
                )}
                {store.api.sdkGeneration && <GetSdkButton/>}
              </div>
            </div>
            <div style={{marginTop: "20px"}} className={'markdown'}>
              <ApiMarkdown file={'/api-files/' + openApiFile + '/overview.md'}/>
            </div>
          </div>
        </Container>
      }
      </Observer>
    )
  }
}

// Create the layout component
class StandardLayout extends React.Component {
  render() {
    const { getComponent } = this.props
    const openApiFile = getOpenApiFile(store.api.swagger.info.title)
    const BaseLayout = getComponent("BaseLayout", true)

    //BaseLayout contains the custom info container
    return (
      <div className="standard-swagger-ui">
        <BaseLayout />
        <div className={'swagger-ui'}>
          <div>
            <div className={'wrapper'}>
              <div className={'block col-12 markdown markdown-dd'}>
                <ApiMarkdown file={'/api-files/' + openApiFile + '/dd.md'}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const SchemesReplacement = ({ specSelectors }) => {
  return (
    <Observer>
      {() => <div>
        <section id="interactive-docs"></section>
        <Header as='h2'>Interactive Docs</Header>
      </div>
      }
    </Observer>
  )
}

const SubscriptionButtons = observer(class SubscriptionButtons extends React.Component {
  state = {}

  render() {
    const { api } = store

    return (
      (api && isAuthenticated()) ? !api.generic ? (
        api.subscribed ? (
          <Button onClick={() => unsubscribe(api.usagePlan.id)}>Unsubscribe</Button>
        ) : (
          <Button onClick={() => subscribe(api.usagePlan.id)} >Subscribe</Button>
        )
      ) : <Header as='h4' color='grey'>This API is not configured for subscription from the portal.</Header> : null
    )
  }
})

export default StandardSwaggerLayoutPlugin
