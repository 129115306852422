// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useMemo } from 'react'

// semantic-ui
import { Button, Header, Container } from 'semantic-ui-react'

// services
import { subscribe, unsubscribe } from 'services/api-catalog'
import { isAuthenticated } from 'services/self'

import { GetSdkButton } from 'components/GetSdk'

// state
import { observer, Observer } from 'mobx-react'
import { store } from 'services/state.js'
import {HAS_SUBSCRIPTIONS} from "../env";

// markdown for external docs description
import { marked } from 'marked'
import DOMPurify from 'dompurify'


import ApiMarkdown from './ApiMarkdown'
import OperationReplacement from "../swagger/core/components/operation-replacement";
import ParametersReplacement from "../swagger/core/components/parameters-replacement";
import ParameterRowReplacement from "../swagger/core/components/parameter-row-replacement";
import ExecuteReplacement from "../swagger/core/components/execute-replacement";
import ResponseReplacement from "../swagger/core/components/response-replacement";
import ResponsesReplacement from "../swagger/core/components/responses-replacement";
import LiveResponseReplacement from "../swagger/core/components/live-response-replacement";
import ModelExampleReplacement from "../swagger/core/components/model-example-replacement";

function Markdown ({ source }) {
    const rendered = useMemo(() => DOMPurify.sanitize(marked(source, {
        headerIds: false,
        silent: true
    })), [source])

    return <div dangerouslySetInnerHTML={{ __html: rendered }} />
}

// Create the plugin that provides our layout component
export const SwaggerLayoutPlugin = () => ({
    components: {
        InfoContainer: InfoReplacement,
        Models: ModelsReplacement,
        SchemesContainer: SchemesReplacement,
        operation: OperationReplacement,
        parameters: ParametersReplacement,
        parameterRow: ParameterRowReplacement,
        execute: ExecuteReplacement,
        response: ResponseReplacement,
        responses: ResponsesReplacement,
        liveResponse: LiveResponseReplacement,
        modelExample: ModelExampleReplacement
} })

function getOpenApiFile(title){
  return title.replace(/\s+/g, '-').toLowerCase()
}

// replaces the InfoContainer component
// https://github.com/swagger-api/swagger-ui/blob/dd3afdc45656bda2a64ae6a7f9bdad006ea98149/src/core/components/layouts/base.jsx

const InfoReplacement = ({ specSelectors }) => {
  const basePath = specSelectors.basePath()
  const host = specSelectors.host()
  const externalDocs = specSelectors.externalDocs()
  const openApiFile = getOpenApiFile(store.api.swagger.info.title)
  const docsDescription = externalDocs.get('description')
  const docsUrl = externalDocs.get('url')

  return (
    <Observer>
      {() =>
        <Container fluid textAlign='left' className="fixfloat" style={{padding: "40px 0px 20px 0px"}}>
          <div style={{display: "flex"}}>
            <div>
              <section id='api-title'></section>
              <Header as='h1'>{store.api.swagger.info.title}</Header>
              <div style={{display: "flex"}}>
                <div style={{marginRight: "20px"}}>
                  <p style={{fontWeight: "bold"}}>Endpoint</p>
                  {/* <p style={{ fontWeight: "bold" }}>Usage Plan</p> */}
                  <p style={{fontWeight: "bold"}}>OpenAPI Specification</p>
                </div>
                <div>
                  <p>https://{host}{basePath}</p>
                  <p><a href={'/api-files/' + openApiFile + '/' + openApiFile + '.yaml'}
                        download>{openApiFile + '.yaml'}</a></p>
                </div>
              </div>
              {externalDocs ? (
                <div style={{paddingBottom: '1em'}}>
                  {docsDescription ? <Markdown source={docsDescription}/> : null}
                  <a href={docsUrl}>{docsUrl}</a>
                </div>
              ) : null}
              {HAS_SUBSCRIPTIONS && (
                <SubscriptionButtons/>
              )}
              {store.api.sdkGeneration && <GetSdkButton/>}
            </div>
          </div>
          <div style={{marginTop: "20px"}} className={'markdown'}>
            <ApiMarkdown file={'/api-files/' + openApiFile + '/overview.md'}/>
          </div>
        </Container>
      }
    </Observer>
)

}

const ModelsReplacement = ({ specSelectors }) => {
  const openApiFile = getOpenApiFile(store.api.swagger.info.title)
  return (
    <Observer>
      {() => <Container fluid textAlign='left' className="fixfloat" style={{padding: "20px 0px"}}>
        <div className={'markdown markdown-dd'}>
          <ApiMarkdown file={'/api-files/' + openApiFile + '/dd.md'}/>
        </div>
      </Container>
      }
    </Observer>
  )
}

const SchemesReplacement = ({ specSelectors }) => {
  return (
    <Observer>
      {() => <div>
        <section id="interactive-docs"></section>
        <Header as='h2'>Interactive Docs</Header>
      </div>
      }
    </Observer>
  )
}

const SubscriptionButtons = observer(class SubscriptionButtons extends React.Component {
  state = {}

  render() {
    const { api } = store
    return (
      (api && isAuthenticated()) ? !api.generic ? (
        api.subscribed ? (
          <Button onClick={() => unsubscribe(api.usagePlan.id)}>Unsubscribe</Button>
        ) : (
          <Button onClick={() => subscribe(api.usagePlan.id)} >Subscribe</Button>
        )
      ) : <Header as='h4' color='grey'>This API is not configured for subscription from the portal.</Header> : null
    )
  }
})

export default SwaggerLayoutPlugin