/*
   THIS COMPONENT WAS COPIED FROM SWAGGER UI.
   IT HAS BEEN CUSTOMIZED FOR THE CC API PORTAL AND RENAMED (Execute to ExecuteReplacement)
   See SwaggerUiLayout.jsx for usage
 */

import React, { Component } from "react"
import PropTypes from "prop-types"

export default class ExecuteReplacement extends Component {

    static propTypes = {
        specSelectors: PropTypes.object.isRequired,
        specActions: PropTypes.object.isRequired,
        operation: PropTypes.object.isRequired,
        path: PropTypes.string.isRequired,
        method: PropTypes.string.isRequired,
        onExecute: PropTypes.func
    }

    onClick=()=>{
        let { specSelectors, specActions, operation, path, method } = this.props

        specActions.validateParams( [path, method] )

        if ( specSelectors.validateBeforeExecute([path, method]) ) {
            if(this.props.onExecute) {
                this.props.onExecute()
            }
            specActions.execute( { operation, path, method } )
        }
    }

    onChangeProducesWrapper = ( val ) => this.props.specActions.changeProducesValue([this.props.path, this.props.method], val)

    render(){
        return (
            <button className="btn execute opblock-control__btn" onClick={ this.onClick }>
                Get API Response
            </button>
        )
    }
}