import React from 'react'
import Markdown from 'react-remarkable'
import frontmatter from "front-matter";

export default class ApiMarkdown extends React.Component {

    // fixes Warning: Can't perform a React state update on an unmounted component
    _isMounted = false;

    constructor(props) {
        super(props)
        this.state = {
            markdown: '',
            file: ''
        }
    }

    loadMarkdown(){
        if (this.state.file !== this.props.file) {
            fetch(this.props.file).then(response => response.text().then(text => {
                // eslint-disable-next-line
                let parsedMarkdown = frontmatter(text.replace('${url}', this.props.url))
                if (this._isMounted) {
                    this.setState({
                        markdown: parsedMarkdown.body,
                        file: this.props.file
                    })
                }
            }))
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadMarkdown()
    }

    componentDidUpdate() {
        this.loadMarkdown()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return <Markdown source={this.state.markdown} options={{html:true}}/>
    }
}