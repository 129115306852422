// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, {Component} from 'react'
import { HashLink as Link } from 'react-router-hash-link'

// semantic-ui
import { Menu, Loader } from 'semantic-ui-react'

// store
import { observer } from 'mobx-react'
import { store } from 'services/state'

// utilities
import _ from 'lodash'
import {HAS_SUBSCRIPTIONS} from "../env";

function isActive(apiId, selectedApiId, stage, selectedStage) {
  if (!selectedStage) return (selectedApiId) ? apiId === selectedApiId : false
  return (selectedApiId) ? apiId === selectedApiId && stage === selectedStage : false
}

export default observer(class ApisMenu extends Component {

    constructor(props) {
        super(props)

        this.state = {
            submenuMap: new Map(),
            bottomSubmenuMap: new Map()
        }
    }

    componentDidUpdate() {
        this.loadSubmenus()
    }

    componentDidMount() {
        this.loadSubmenus()
    }

    loadSubmenus() {
        if (store.apiList.loaded) {
            if (this.state.submenuMap.size < store.apiList.generic.length) {
                store.apiList.generic.forEach((a) => {
                    let apiTitle = a.swagger.info.title.replace(/\s+/g, '-').toLowerCase()

                    fetch(`/api-files/${apiTitle}/overview.md`)
                        .then(response => response.text())
                        .then(text => {
                            let submenuSections = this.parseSubmenus(text)
                            let submenuMap = this.state.submenuMap
                            submenuMap.set(a.id, submenuSections)
                            this.setState({
                                submenuMap: submenuMap
                            })
                        })
                    fetch(`/api-files/${apiTitle}/dd.md`)
                        .then(response => response.text())
                        .then(text => {
                            let bottomSubmenuSections = this.parseSubmenus(text)
                            let bottomSubmenuMap = this.state.bottomSubmenuMap
                            bottomSubmenuMap.set(a.id, bottomSubmenuSections)
                            this.setState({
                                bottomSubmenuMap: bottomSubmenuMap
                            })
                        })
                })
            }
        }
    }

    parseSubmenus(text) {
        let submenuSections = []
        if (text.includes('<section ')) {
            let values = text.split('<section ')
            values.forEach((v) => {
                if (v !== "") {
                    let subMenuSection = {}
                    let idStartIndex = v.indexOf('id="')
                    subMenuSection.sectionId = v.substring(idStartIndex, v.indexOf('"', idStartIndex + 4)).replace('id="', '')
                    let nameStartIndex = v.indexOf('name="')
                    subMenuSection.sectionName = v.substring(nameStartIndex, v.indexOf('"', nameStartIndex + 6)).replace('name="', '')
                    submenuSections.push(subMenuSection)
                }
            })
        }
        return submenuSections
    }

    render() {

        const loadingApis = !store.apiList.loaded
        const hasGatewayApis = !!_.get(store, 'apiList.apiGateway.length')
        const hasGenericApis = !!_.get(store, 'apiList.generic.length')

        // either grab the selectedApiId from the path OR
        // grab it from the first apiGateway api OR
        // grab it from the first generic api
        let selectedApiId = (
            this.props.path.params.apiId ||
            (hasGatewayApis && store.apiList.apiGateway[0].id) ||
            (hasGenericApis && store.apiList.generic[0].id)
        )

        if (this.props.path.url === '/apis/search'
            || this.props.path.url === '/introduction'
            || this.props.path.url === '/') {
            selectedApiId = false
        }

        let selectedStage = (this.props.path.params.stage || (hasGatewayApis && store.apiList.apiGateway[0].stage))

        // If we're still loading, display a spinner.
        // If we're not loading, and we don't have any apis, display a message.
        // If we're not loading, and we have some apis, render the appropriate api subsections for apiGateway and generic apis
        return (
            <Menu inverted vertical attached style={{
                margin: 0,
                borderRadius: 0,
                flex: "0 0 auto",
                position: "relative",
                overflowY: "scroll"
            }} {...this.props}>
                {loadingApis && <Loader active/>}
                <React.Fragment>
                    <Menu.Item key="search" as={Link} to="/apis/search" active={this.props.path.url === '/apis/search'}>Search
                        APIs</Menu.Item>
                    <Menu.Header style={{padding: "13px 5px 13px 16px", color: '#FCAE00'}}>API PLATFORM</Menu.Header>
                    <Menu.Item key="introduction" active={this.props.path.url === '/' || this.props.path.url === '/introduction'}><Link
                        smooth to="/introduction#introduction">Introduction</Link></Menu.Item>
                    <Menu.Item key="registration" active={this.props.path.url === '/' || this.props.path.url === '/introduction'}><Link
                        smooth to="/introduction#registration">Registration</Link></Menu.Item>
                    <Menu.Item key="faq" active={this.props.path.url === '/' || this.props.path.url === '/introduction'}><Link
                        smooth to="/introduction#faq">FAQ</Link></Menu.Item>
                    <Menu.Header style={{padding: "13px 5px 13px 16px", color: '#FCAE00'}}>API EXPLORER</Menu.Header>
                    {hasGatewayApis && <ApiSubsection title="Subscribable" listOfApis={store.apiList.apiGateway}
                                                      selectedApiId={selectedApiId} selectedStage={selectedStage}/>}
                    {hasGenericApis && <GenericApiSubsection title="Not Subscribable" listOfApis={store.apiList.generic}
                                                             selectedApiId={selectedApiId} submenuMap={this.state.submenuMap} bottomSubmenuMap={this.state.bottomSubmenuMap}/>}
                </React.Fragment>
            </Menu>
        )
    }
})

function GenericApiSubsection({title, listOfApis, selectedApiId, submenuMap, bottomSubmenuMap}) {
    listOfApis = listOfApis.slice().sort((a, b) => {
        return a.swagger.info.title > b.swagger.info.title ? 1 : -1;
    })
    return (
        <React.Fragment>
            {HAS_SUBSCRIPTIONS && (
                <Menu.Header
                    style={{
                        padding: "13px 5px 13px 16px",
                        color: '#FCAE00'
                    }}
                >
                    {title}
                </Menu.Header>
            )}
            {listOfApis.map(api => (
                <div key={api.id}>
                    <Menu.Item
                        key={api.id}
                        active={isActive(api.id.toString(), selectedApiId.toString())}>
                        <Link smooth to={`/apis/${api.id}#api-title`}>{api.swagger.info.title}</Link>
                    </Menu.Item>
                    {isActive(api.id.toString(), selectedApiId.toString()) && (
                        <SubMenuSection api={api} submenuMap={submenuMap} bottomSubmenuMap={bottomSubmenuMap}/>
                    )}
                </div>
            ))}
        </React.Fragment>
    )
}

function ApiSubsection({title, listOfApis, selectedApiId, selectedStage = false}) {
    return (
        <React.Fragment>
            {HAS_SUBSCRIPTIONS && (
                <Menu.Header
                    style={{
                        padding: "13px 5px 13px 16px",
                        color: '#FCAE00'
                    }}
                >
                    {title}
                </Menu.Header>
            )}
            {listOfApis.map(api => (
                <div key={`${api.id}_${api.stage}`}>
                    <Menu.Item
                        key={`${api.id}_${api.stage}`}
                        active={isActive(api.id.toString(), selectedApiId.toString(), api.stage.toString(), selectedStage)}>
                        <Link smooth to={`/apis/${api.id}/${api.stage}#api-title`}>{api.swagger.info.title}</Link>
                    </Menu.Item>
                    {isActive(api.id.toString(), selectedApiId.toString(), api.stage.toString(), selectedStage) && (
                        <SubMenuSection/>
                    )}
                </div>
            ))}
        </React.Fragment>
    )
}

function SubMenuSection({api, submenuMap, bottomSubmenuMap}) {
    return (
        <div>
            {submenuMap.has(api.id) && submenuMap.get(api.id).map(subMenuSection => (
                <Menu.Item key={`${api.id}_${subMenuSection.sectionId}`} style={{paddingLeft: '30px'}} className={'active'}><Link smooth to={`/apis/${api.id}#${subMenuSection.sectionId}`}>{subMenuSection.sectionName}</Link></Menu.Item>
            ))}
            <Menu.Item style={{paddingLeft: '30px'}} className={'active'}><Link smooth to={`/apis/${api.id}#interactive-docs`}>Interactive Docs</Link></Menu.Item>
            {bottomSubmenuMap.has(api.id) && bottomSubmenuMap.get(api.id).map(subMenuSection => (
                <Menu.Item key={`${api.id}_${subMenuSection.sectionId}`} style={{paddingLeft: '30px'}} className={'active'}><Link smooth to={`/apis/${api.id}#${subMenuSection.sectionId}`}>{subMenuSection.sectionName}</Link></Menu.Item>
            ))}
        </div>
    )
}