// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { updateUsagePlansAndApisList } from 'services/api-catalog';

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// semantic-ui
import {Container} from 'semantic-ui-react'
import ApisMenu from "../components/ApisMenu";

export default observer(class IntroductionPage extends React.Component {
    componentDidMount() { updateUsagePlansAndApisList(true) }

    render() {
        return (
            <div style={{ display: "flex", flex: "1 1 auto", overflow: "hidden"}} className={'intro-section'}>
                <ApisMenu path={this.props.match} />
                <div style={{ flex: "1 1 auto", overflow: 'auto'}}>
                    <Container style={{ padding: "40px"}} className={'markdown markdown-content'}>
                        <fragments.Introduction.jsx />
                    </Container>
                    <div style={{ display: "block", paddingBottom: '1000px'}}></div>
                </div>
            </div>
        )
    }
})
