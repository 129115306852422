/*
   THIS FILE WAS COPIED FROM SWAGGER UI, IT IS NEEDED AS A DEPENDENCY FOR OVERRIDES.
   NO CHANGES WERE MADE TO THIS FILE
 */

function makeWindow() {
    var win = {
        location: {},
        history: {},
        open: () => {},
        close: () => {},
        File: function() {}
    }

    if(typeof window === "undefined") {
        return win
    }

    try {
        win = window
        var props = ["File", "Blob", "FormData"]
        for (var prop of props) {
            if (prop in window) {
                win[prop] = window[prop]
            }
        }
    } catch( e ) {
        console.error(e)
    }

    return win
}

module.exports = makeWindow()