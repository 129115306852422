
import React from 'react'
import {Redirect} from 'react-router-dom'
import { Menu, Image } from 'semantic-ui-react'
import { isAuthenticated, getLoginRedirectUrl } from 'services/self'
import { cognitoDomain, cognitoClientId } from '../services/api'

// components
import Register from './Register'

export default class UserLogin extends React.Component {
    getCognitoUrl = (type) => {
      let redirectUri = getLoginRedirectUrl()
      return `${cognitoDomain}/${type}?response_type=token&client_id=${cognitoClientId}&redirect_uri=${redirectUri}`
    }

    render() {
     if (isAuthenticated()) {
        return <Redirect to="/" />
     }

      return <Menu inverted borderless attached style={{ flex: "0 0 auto" }} >
        <Menu.Item>
          <Image size='mini' src="/custom-content/nav-logo.png" style={{ paddingRight: "30px" }} />
        </Menu.Item>

          <Menu.Menu position="right">
              <Menu.Item key="register" as="a"
                         href={this.getCognitoUrl('login')}>
                  Sign In
              </Menu.Item>
              <Register />
          </Menu.Menu>
      </Menu >
    }
}

